import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 612.000000 428.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1076.000000) scale(0.100000,-0.100000)">
          <path d="M5170 9439 c-174 -15 -434 -50 -485 -64 -58 -16 -111 -28 -215 -50
-41 -8 -84 -20 -95 -25 -11 -5 -63 -20 -115 -34 -88 -23 -209 -64 -385 -131
-174 -66 -526 -249 -690 -359 -33 -22 -71 -47 -85 -56 -114 -75 -374 -278
-460 -360 -178 -169 -384 -386 -444 -469 -12 -16 -52 -68 -89 -116 -120 -153
-207 -288 -318 -495 -96 -178 -147 -284 -203 -422 -44 -107 -89 -237 -121
-348 -14 -47 -31 -107 -39 -135 -8 -27 -24 -99 -36 -160 -11 -60 -27 -138 -35
-172 -8 -34 -15 -79 -15 -100 0 -21 -6 -48 -14 -59 -23 -33 -39 -301 -34 -584
5 -248 18 -392 53 -570 9 -41 24 -122 36 -180 11 -58 31 -141 44 -185 13 -44
33 -114 43 -155 84 -318 363 -890 573 -1171 117 -158 306 -385 418 -501 22
-24 243 -222 316 -284 67 -58 264 -206 322 -243 481 -308 865 -477 1358 -599
507 -125 1050 -154 1555 -82 317 45 461 78 780 177 267 83 623 242 835 373 39
23 86 52 105 63 50 30 185 123 249 171 29 23 66 50 80 61 171 128 482 426 626
600 358 432 596 857 767 1370 35 105 52 168 113 415 7 30 21 116 30 190 9 74
21 156 26 181 16 71 18 106 25 340 8 259 -8 532 -37 622 -10 33 -19 76 -19 96
-1 110 -135 618 -206 782 -13 31 -24 59 -24 63 0 3 -16 40 -35 82 -19 41 -35
78 -35 81 0 4 -34 74 -75 156 -358 712 -948 1331 -1655 1737 -143 82 -493 250
-520 250 -7 0 -24 7 -38 15 -35 19 -237 88 -382 130 -99 28 -166 44 -340 81
-41 9 -41 9 -60 17 -8 3 -37 8 -65 11 -27 4 -84 11 -125 16 -295 38 -631 49
-860 29z m-526 -2113 c246 -48 398 -124 528 -265 118 -128 163 -254 156 -441
-5 -126 -26 -195 -86 -283 -81 -116 -264 -248 -415 -297 -21 -6 -36 -14 -33
-17 3 -3 53 -25 112 -49 170 -69 308 -167 380 -267 19 -27 24 -29 164 -42 80
-8 164 -16 188 -19 l42 -6 0 649 0 649 -27 22 c-16 12 -47 35 -71 51 -23 16
-47 41 -52 55 -13 33 -13 169 0 215 20 70 8 69 634 69 610 0 615 0 637 -57 7
-17 9 -66 6 -126 -6 -115 -14 -128 -110 -179 -36 -20 -59 -39 -62 -52 -2 -12
-6 -194 -8 -406 -5 -359 -4 -383 12 -361 27 40 428 784 435 807 5 18 -2 27
-44 54 -67 43 -84 76 -84 172 -1 85 18 126 65 139 13 4 172 7 352 8 366 1 386
-2 406 -61 14 -39 14 -132 0 -181 -12 -43 -36 -62 -128 -97 -75 -29 -64 -15
-288 -370 l-117 -184 95 -279 c51 -153 97 -283 100 -289 7 -11 126 18 135 33
10 17 34 9 34 -11 0 -14 -22 -34 -71 -66 -55 -35 -69 -49 -64 -62 13 -35 157
-460 182 -539 15 -46 31 -83 38 -83 6 0 50 13 98 28 48 16 90 25 93 21 5 -8
-75 -62 -137 -94 -19 -10 -35 -18 -36 -19 -6 -5 128 -390 142 -413 9 -13 40
-39 69 -58 67 -42 81 -75 78 -175 -2 -90 -13 -121 -51 -143 -26 -15 -78 -17
-540 -17 -352 0 -518 3 -534 11 -36 17 -47 50 -47 148 0 102 13 128 79 154 22
9 42 20 44 24 3 5 -15 71 -39 146 l-45 138 -117 -3 -117 -3 0 -111 c1 -126 9
-146 75 -190 47 -31 70 -77 70 -139 0 -80 -19 -135 -54 -156 -29 -18 -59 -19
-571 -19 -415 0 -546 3 -565 13 -42 21 -63 80 -58 163 4 88 22 116 97 155 29
15 56 31 59 34 4 4 7 65 7 136 l0 129 -30 0 c-42 0 -158 -23 -241 -49 l-70
-22 -31 -62 c-109 -221 -311 -368 -604 -441 -203 -51 -305 -56 -1036 -56
l-672 0 -28 24 c-24 21 -28 32 -31 95 -7 128 9 163 95 201 24 10 54 30 68 44
l25 25 0 236 0 237 -37 -23 c-21 -12 -69 -42 -106 -66 -85 -55 -135 -73 -205
-73 -79 0 -130 19 -178 65 -67 64 -70 157 -7 217 35 33 247 153 411 232 l122
60 0 702 0 701 -74 33 c-102 44 -109 53 -114 153 -6 118 24 172 103 191 11 2
355 3 765 2 663 -2 755 -4 834 -20z m-649 -3300 c35 -15 75 -67 75 -98 0 -14
-8 -18 -33 -18 -26 0 -36 6 -45 25 -21 45 -113 44 -120 -1 -4 -27 10 -37 89
-63 89 -29 114 -57 114 -126 0 -43 -5 -59 -24 -79 -54 -57 -171 -60 -222 -5
-23 25 -46 76 -37 84 2 1 17 5 33 9 26 5 32 2 47 -24 27 -48 104 -52 132 -7
18 29 -5 50 -85 77 -59 20 -78 32 -96 59 -73 108 43 220 172 167z m3086 -23
c15 -15 30 -43 34 -60 6 -32 6 -33 -28 -33 -26 0 -36 5 -45 25 -21 45 -113 44
-120 -1 -4 -27 10 -37 89 -63 89 -29 114 -57 114 -126 0 -43 -5 -59 -24 -79
-54 -57 -171 -60 -222 -5 -23 25 -46 76 -37 84 2 1 17 5 33 9 26 5 32 2 47
-24 27 -48 104 -52 132 -7 18 28 -4 48 -80 74 -83 28 -114 56 -121 110 -11 85
47 137 146 129 43 -4 60 -11 82 -33z m-3756 12 c56 -27 70 -86 34 -144 l-20
-33 20 -19 c28 -26 36 -64 22 -108 -20 -67 -38 -76 -172 -80 l-119 -3 0 201 0
201 101 0 c70 0 111 -5 134 -15z m298 -2 c4 -16 72 -205 121 -340 l15 -43 -38
0 c-37 0 -39 2 -51 45 l-13 45 -72 0 -72 0 -13 -45 c-12 -43 -14 -45 -51 -45
l-38 0 15 43 c38 103 116 324 121 340 4 12 16 17 38 17 22 0 34 -5 38 -17z
m597 -63 l0 -80 70 0 70 0 0 80 0 80 35 0 35 0 0 -200 0 -200 -35 0 -35 0 0
85 0 85 -70 0 -70 0 0 -85 0 -85 -35 0 -35 0 0 200 0 200 35 0 35 0 0 -80z
m360 -2 l1 -83 32 40 c18 21 50 59 72 83 37 40 43 43 83 40 l43 -3 -62 -73
c-51 -60 -61 -77 -52 -90 5 -10 36 -65 67 -124 l58 -108 -40 0 c-39 0 -41 1
-80 73 -22 39 -47 80 -55 91 -13 18 -15 18 -40 -10 -23 -25 -27 -37 -27 -91
l0 -63 -35 0 -35 0 0 200 0 200 35 0 35 0 0 -82z m494 -25 c21 -60 53 -150 72
-200 l33 -93 -38 0 c-37 0 -39 2 -51 45 l-13 45 -72 0 -72 0 -13 -45 c-12 -43
-14 -45 -51 -45 l-38 0 33 93 c18 50 50 140 71 200 37 106 38 107 69 107 32 0
33 -1 70 -107z m226 -58 l0 -165 90 0 90 0 0 -35 0 -35 -125 0 -125 0 0 200 0
200 35 0 35 0 0 -165z m443 156 c66 -25 104 -115 94 -222 -6 -66 -28 -112 -70
-143 -23 -17 -46 -22 -128 -24 l-99 -4 0 201 0 201 89 0 c49 0 101 -4 114 -9z
m361 -98 c21 -60 53 -150 72 -200 l33 -93 -38 0 c-37 0 -39 2 -51 45 l-13 45
-72 0 -72 0 -13 -45 c-12 -43 -14 -45 -51 -45 l-38 0 33 93 c18 50 50 140 71
200 37 106 38 107 69 107 32 0 33 -1 70 -107z m396 72 l0 -35 -55 0 -55 0 0
-165 0 -165 -35 0 -35 0 0 165 0 165 -55 0 -55 0 0 35 0 35 145 0 145 0 0 -35z
m163 18 c5 -17 88 -252 121 -340 l15 -43 -38 0 c-37 0 -39 2 -51 45 l-13 45
-72 0 -72 0 -13 -45 c-12 -43 -14 -45 -51 -45 l-38 0 15 43 c31 82 124 350
124 353 0 2 15 4 34 4 23 0 35 -5 39 -17z m597 -131 c0 -135 2 -150 20 -170
24 -26 66 -29 98 -6 21 15 22 21 22 170 l0 154 35 0 35 0 0 -150 c0 -210 -18
-247 -127 -257 -50 -4 -63 -2 -93 19 -50 34 -59 70 -59 241 l-1 147 35 0 35 0
0 -148z m529 137 c34 -12 61 -53 61 -91 0 -13 -9 -38 -20 -56 -20 -32 -20 -32
0 -52 27 -27 35 -65 21 -109 -20 -67 -38 -76 -172 -79 l-119 -4 0 201 0 201
99 0 c54 0 112 -5 130 -11z"/>
<path d="M4075 7049 c-3 -8 -4 -207 -3 -444 l3 -430 43 -3 c74 -5 152 16 188
52 49 49 56 87 61 351 6 329 -11 411 -97 455 -49 25 -187 38 -195 19z"/>
<path d="M4070 5821 l0 -61 58 0 c33 0 93 -3 135 -6 74 -7 77 -6 77 15 0 33
-30 72 -66 87 -18 8 -71 17 -118 20 l-86 7 0 -62z"/>
<path d="M6631 5458 c-14 -39 -14 -395 0 -403 4 -3 47 -9 94 -13 75 -7 86 -6
82 8 -25 84 -153 440 -158 440 -4 0 -12 -15 -18 -32z"/>
<path d="M4097 5453 l-27 -4 2 -432 3 -432 55 3 c127 6 188 38 211 113 15 50
31 463 23 622 l-6 137 -117 -1 c-64 -1 -128 -4 -144 -6z"/>
<path d="M5405 5255 c0 -171 1 -176 20 -171 11 4 73 11 137 18 65 7 119 17
120 22 2 6 2 75 0 154 l-4 142 -108 0 c-59 0 -120 3 -136 6 l-29 6 0 -177z"/>
<path d="M3160 3915 l0 -45 56 0 c66 0 94 13 94 43 0 37 -19 47 -87 47 l-63 0
0 -45z"/>
<path d="M3160 3750 l0 -50 61 0 c71 0 99 12 99 44 0 39 -30 56 -98 56 l-62 0
0 -50z"/>
<path d="M3572 3893 c-12 -32 -32 -94 -32 -99 0 -2 21 -4 46 -4 36 0 45 3 41
14 -3 8 -11 32 -16 53 -17 60 -26 69 -39 36z"/>
<path d="M4992 3893 c-12 -32 -32 -94 -32 -99 0 -2 21 -4 46 -4 36 0 45 3 41
14 -3 8 -11 32 -16 53 -17 60 -26 69 -39 36z"/>
<path d="M5610 3830 l0 -130 53 0 c87 0 113 38 105 152 -6 80 -34 108 -109
108 l-49 0 0 -130z"/>
<path d="M6022 3893 c-12 -32 -32 -94 -32 -99 0 -2 21 -4 46 -4 36 0 45 3 41
14 -3 8 -11 32 -16 53 -17 60 -26 69 -39 36z"/>
<path d="M6612 3893 c-12 -32 -32 -94 -32 -99 0 -2 21 -4 46 -4 36 0 45 3 41
14 -3 8 -11 32 -16 53 -17 60 -26 69 -39 36z"/>
<path d="M7630 3915 l0 -45 65 0 c61 0 66 2 77 26 20 43 1 59 -76 62 l-66 3 0
-46z"/>
<path d="M7630 3750 l0 -50 61 0 c71 0 99 12 99 44 0 39 -30 56 -98 56 l-62 0
0 -50z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
